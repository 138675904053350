/* eslint no-shadow: ['error', { 'allow': ['state', 'getters'] }] */
/**
 * The base store for the FCO multi-page application.
 * All apps within FCO can leverage this store to get core data about the app, user, account, etc.
 *
 * If an app needs to add modules to this store, dynamic module registration can be leveraged.
 * https://vuex.vuejs.org/guide/modules.html#dynamic-module-registration
 *
 * Individual store properties (state, mutations, actions, etc.) are exported mainly for testing purposes.
 * Unless our backend data structure dictates otherwise, they should not be imported into another store for the sake of extending this one.
 * We should try to keep app-level state in one place.
 */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import delay from 'fco/src/utils/delay';

import { fcoUrl } from '@/fcoModules/utilities';
import { selectShop, getCurrentUserShops, getShopDetailsById } from '../services/shopService';
import { createRequestStatus, requestSettled, trackRequestStatus, updateRequestStatus, isRequestIdle } from './request-status';
import {
    declineUserEmailUpdate,
    getCurrentUser,
    getCurrentUserLegacy,
    masquerade,
    masqueradeLegacy,
    saveUserEmail,
    saveUserPreferences,
    stopMasquerade,
    stopMasqueradeLegacy,
} from '../services/userService';
import { saveCompanyPreferences } from '../services/companyService';
import { getFeatures } from '../services/featuresService';
import { setLivePersonAuthenticated } from '../utilities/liveperson';
import { Locale, Role } from '../constants/user';

// Store Modules
import vehicleSelector from './modules/vehicleSelector';
import miniQuote from './modules/miniQuote';
import stockOrderQuickAdd from './modules/stockOrderQuickAdd';
import opp from './modules/opp';
import kits from './modules/kits';
import partSelection from './modules/partSelection';
import { getStorage, getStorageItem, removeStorageItem, setStorageItem, StorageKey } from './store-utils';
import { getCMSData } from '../services/cmsService';
import { getIsSPA } from '../../appMode';

Vue.use(Vuex);

const setAuthorizationHeader = (authData) => {
    if (!authData) {
        delete axios.defaults.headers.common.Authorization;
    } else {
        const { access_token: accessToken } = authData;
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }
};

const authWithExpiration = (auth) => {
    if (!auth) return null;
    const now = Date.now();
    const fiveMinutes = 300000;
    return {
        ...auth,
        accessExpiresOn: now + auth.access_expiration - fiveMinutes,
        refreshExpiresOn: now + auth.refresh_expiration - fiveMinutes,
    };
};

const cachedAuth = getStorageItem(StorageKey.AUTH);
setAuthorizationHeader(cachedAuth);

const getDefaultUser = () =>
    getIsSPA()
        ? null
        : {
              authenticated: false,
              carCustomer: false,
              customerType: {
                  description: '',
                  name: '',
              },
              customerTypeSms: false,
              emailAddress: '',
              emailUpdateRequired: false,
              hideStorePhoneNumber: false,
              firstName: '',
              lastName: '',
              loginName: '',
              masquerade: false,
              partsPayoffAccessible: false,
              passwordChangeRequired: false,
              shopReferralEligible: false,
              teamMember: false,
              userId: null,
              userPreference: {
                  languageCode: Locale.EN,
                  catalogNavigationTab: 1,
                  selectedStylesheet: 1,
                  selectedShopId: 0,
              },
              group: '',
              cxmlCallbackUrl: '',
          };

const getDefaultCompany = () => ({
    demo: false,
    id: 0,
    address: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        flatAddress: '',
        completeAddress: false,
        notEmpty: false,
        combinedStreetAddress: '',
    },
    companyPreference: {
        garageBayIdVisible: false,
        garageBayIdRequired: false,
        vehicleFleetIdVisible: false,
        vehicleFleetIdRequired: false,
        poVisible: false,
        poRequired: false,
        companyId: 0,
        id: 0,
        poValidationMask: '',
    },
    accountStatus: '',
    shopRequests: [
        {
            garageIdRequired: false,
            garageBayIdVisible: false,
            poRequired: false,
            poVisible: false,
            vehicleFleetIdRequired: false,
            vehicleFleetIdVisible: false,
            requested: false,
            approved: false,
            pending: false,
            declined: false,
            arAccountNumber: 0,
            shopAddress: {
                street1: '',
                street2: '',
                city: '',
                state: '',
                zip: '',
                flatAddress: '',
                completeAddress: false,
                notEmpty: false,
                combinedStreetAddress: '',
            },
            laborRate: 0,
            pricingTypeModification: 0,
            taxRateLabor: 0,
            taxRateParts: 0,
            companyId: 0,
            notes: [
                {
                    id: 0,
                    text: '',
                    createdByUser: {
                        firstName: '',
                        lastName: '',
                        loginName: '',
                    },
                    createDateTime: '',
                    internal: false,
                    shopRequestId: 0,
                },
            ],
            id: 0,
            pricingMethod: '',
            pricingType: '',
            requestStatus: '',
            customerLocationId: '',
            miscInfo: '',
            poValidationMask: '',
            shopFax: '',
            shopName: '',
            shopPhone: '',
            createDateTime: '',
        },
    ],
    accountName: '',
    arGroupCode: '',
    shopReferralEligible: false,
    partsPayoffVisible: false,
    paymentMethod: '',
});

const getDefaultCmsData = () => ({ url: '', token: '' });

export const state = {
    isSPA: getIsSPA(),
    auth: cachedAuth,
    csrfObject: {
        parameterName: '_csrf',
        headerName: 'X-CSRF-TOKEN',
        token: '',
    },
    googleMapsKey: '',
    imageServerUrl: 'https://images.firstcallonline.com',
    promoImagesUrl: '',
    teamviewerAndroidURL: '',
    teamviewerChromeURL: '',
    teamviewerIosURL: '',
    teamviewerMacUrl: '',
    teamviewerWindowsURL: '',
    version: {
        copyright: `Copyright © 2011-${new Date().getFullYear()}`,
        fcoVersion: '',
        node: '',
        ocatVersion: '',
    },
    user: getStorageItem(StorageKey.USER) || getDefaultUser(),
    stickySessionId: getStorageItem(StorageKey.STICKY_SESSION_ID),
    isPasswordChangeRequired: false,
    isTermsAcceptRequired: false,
    masqueradeRealUser: getStorageItem(StorageKey.MASQUERADE_REAL_USER),
    masqueradeSource: getStorage().getItem(StorageKey.MASQUERADE_SOURCE),
    isCostHidden: getStorageItem(StorageKey.IS_COST_HIDDEN) || false,
    shops: [],
    currentShop: null,
    shopRequests: [],
    USStates: [],
    jobTitles: [],
    customerTypes: [],
    company: getDefaultCompany(),
    scrollToOffset: 0,
    profile: '',
    cmsData: getDefaultCmsData(),
    features: getStorageItem(StorageKey.FEATURES) || [],
    tsm: {
        tsmEmailAddress: '',
        tsmFirstName: '',
        tsmLastName: '',
        tsmNumber: 0,
    },
    requests: {
        getAppData: createRequestStatus(),
        getUser: createRequestStatus(),
        getUserShops: createRequestStatus(),
        getCurrentShop: createRequestStatus(),
        getStickySessionId: createRequestStatus(),
        getTSM: createRequestStatus(),
        getFeatures: createRequestStatus(),
        getCMSData: createRequestStatus(),
        getUSStates: createRequestStatus(),
        getJobTitles: createRequestStatus(),
        getCustomerTypes: createRequestStatus(),
        getShopRequests: createRequestStatus(),
        getCompany: createRequestStatus(),
    },
};

const mutations = {
    updateRequestStatus,
    setUSStates(state, value) {
        state.USStates = value;
    },
    setJobTitles(state, value) {
        state.jobTitles = value;
    },
    setCustomerTypes(state, value) {
        state.customerTypes = value;
    },
    setCompany(state, data) {
        state.company = data;
    },
    setAuthTokens(state, auth) {
        state.auth = auth || null;
        setAuthorizationHeader(state.auth);
        if (!state.auth) {
            removeStorageItem(StorageKey.AUTH);
        } else {
            setStorageItem(StorageKey.AUTH, state.auth);
        }
    },
    setUser(state, user) {
        state.user = user?.userId ? user : getDefaultUser();
        setStorageItem(StorageKey.USER, state.user);
    },
    setStickySessionId(state, stickySessionId) {
        state.stickySessionId = stickySessionId;
        if (stickySessionId == null) {
            removeStorageItem(StorageKey.STICKY_SESSION_ID);
        } else {
            setStorageItem(StorageKey.STICKY_SESSION_ID, stickySessionId);
        }
    },
    setMasqueradeRealUser(state, masqueradeRealUser) {
        state.masqueradeRealUser = masqueradeRealUser;
        if (!state.masqueradeRealUser) {
            removeStorageItem(StorageKey.MASQUERADE_REAL_USER);
        } else {
            setStorageItem(StorageKey.MASQUERADE_REAL_USER, state.masqueradeRealUser);
        }
    },
    setMasqueradeSource(state, masqueradeSource) {
        state.masqueradeSource = masqueradeSource;
        if (!masqueradeSource) removeStorageItem(StorageKey.MASQUERADE_SOURCE);
        else setStorageItem(StorageKey.MASQUERADE_SOURCE, masqueradeSource);
    },
    setShops(state, shops) {
        state.shops = shops || [];
    },
    setFeatures(state, features) {
        state.features = features || [];
        // Amount of time (ms) to cache features on the FE before they are considered expired.
        // Features will still be loaded in the background, even when cached, but this
        // allows us to immediately load up the correct UI features without having to wait
        // for the features request on subsequent page loads.
        const fifteenMinutes = 900000;
        setStorageItem(StorageKey.FEATURES, state.features, fifteenMinutes);
    },
    setCurrentShop(state, value) {
        state.currentShop = !value ? null : { ...value };
    },
    setShopRequests(state, shopRequests) {
        state.shopRequests = shopRequests || [];
    },
    setAppData(
        state,
        {
            csrfObject,
            googleMapsKey,
            imageServerUrl,
            version,
            promoImagesUrl,
            profile,
            teamviewerAndroidURL,
            teamviewerChromeURL,
            teamviewerIosURL,
            teamviewerMacURL,
            teamviewerWindowsURL,
        }
    ) {
        state.csrfObject = csrfObject;
        state.googleMapsKey = googleMapsKey;
        state.imageServerUrl = imageServerUrl;
        state.version = version;
        state.promoImagesUrl = promoImagesUrl;
        state.profile = profile;
        state.teamviewerAndroidURL = teamviewerAndroidURL;
        state.teamviewerChromeURL = teamviewerChromeURL;
        state.teamviewerIosURL = teamviewerIosURL;
        state.teamviewerMacUrl = teamviewerMacURL;
        state.teamviewerWindowsURL = teamviewerWindowsURL;
        // In the multi-page Spring app, we need the CSRF with most of our requests. If a CSRF token was returned, assign it to the default axios header config.
        if (!state.isSPA && csrfObject?.token) {
            axios.defaults.headers.common['X-CSRF-Token'] = csrfObject.token;
        }
    },
    setIsCostHidden(state, value) {
        state.isCostHidden = value;
        setStorageItem(StorageKey.IS_COST_HIDDEN, state.isCostHidden);
    },
    updateUserPreferences(
        state,
        {
            firstName = state.user.firstName,
            lastName = state.user.lastName,
            emailAddress = state.user.emailAddress,
            loginName = state.user.loginName,
            selectedStylesheet = state.user.userPreference?.selectedStylesheet,
            languageCode = state.user.userPreference?.languageCode,
            selectedShopId = state.user.userPreference?.selectedShopId,
        } = {}
    ) {
        state.user = {
            ...state.user,
            firstName,
            lastName,
            emailAddress,
            loginName,
            userPreference: {
                ...state.user.userPreference,
                selectedStylesheet,
                languageCode,
                selectedShopId,
            },
        };
        setStorageItem(StorageKey.USER, state.user);
    },
    updateCompanyPreferences(state, { accountName, demo, address, companyPreference }) {
        state.currentShop.company = {
            ...state.currentShop.company,
            accountName,
            address,
            demo,
            companyPreference,
        };
    },
    setUserEmailAddress(state, emailAddress) {
        state.user.emailAddress = emailAddress;
        setStorageItem(StorageKey.USER, state.user);
    },
    setUserEmailUpdateRequired(state, emailUpdateRequired) {
        state.user.emailUpdateRequired = emailUpdateRequired;
    },
    setScrollToOffset(state, value) {
        state.scrollToOffset = Number(value) || 0;
    },
    setCMSData(state, data) {
        state.cmsData = data;
    },
    setTSM(state, data) {
        state.tsm = data;
    },
};

let refreshTimeout;
const actions = {
    /**
     * Given a list of request names, dispatches (if idle) and awaits the corresponding requests.
     * This allows components to succinctly request and wait for data they depend on:
     * @example await this.$store.dispatch('requestIfIdle', ['getUser', 'getCompany'])
     */
    async requestIfIdle({ dispatch }, requestNames = []) {
        await dispatch('requestAll', { requests: requestNames, force: false });
    },
    /**
     * Given a list of request names, dispatches and awaits the corresponding requests.
     * If you only want to request if idle, use 'requestIfIdle' instead (see above).
     */
    async requestAll({ state, dispatch }, { requests = [], force = true }) {
        // map the requests so we can handle a mix of root and namespaced actions (i.e. 'miniQuote/getQuoteData')
        const namespacedRequests = requests.map((requestName) => {
            let namespace = '';
            let finalRequestName = requestName;
            if (requestName.indexOf('/') > -1) {
                [namespace, finalRequestName] = requestName.split('/');
            }
            return {
                action: requestName,
                requestName: finalRequestName,
                namespacedState: namespace ? state[namespace] : state,
            };
        });
        namespacedRequests.forEach(({ namespacedState, requestName, action }) => {
            if (force || isRequestIdle(namespacedState.requests[requestName])) dispatch(action);
        });
        await Promise.all(namespacedRequests.map(({ namespacedState, requestName }) => requestSettled(() => namespacedState.requests[requestName])));
    },
    async login({ commit, dispatch }, loginForm) {
        clearTimeout(refreshTimeout);
        const { data } = await axios.post(fcoUrl('/auth/rest/login'), loginForm);
        commit('setAuthTokens', authWithExpiration(data));
        dispatch('startRefreshTimer');
    },
    async refreshAuth({ state, commit, dispatch, getters }, abortWhenValid = false) {
        const refreshLockKey = 'fcoRefreshTokenLock';

        clearTimeout(refreshTimeout);

        // A mutex-style lock to prevent multiple simultaneous requests to refresh the token
        // If this is true, that means something else - either in this tab/window or another one - is already refreshing the token
        if (localStorage.getItem(refreshLockKey)) {
            // Retry the refresh request after a short delay.
            await delay(100);
            // Only refresh if the token hasn't already been updated.
            return dispatch('refreshAuth', true);
        }

        if (getters.isAuthExpired || !abortWhenValid) {
            try {
                localStorage.setItem(refreshLockKey, 'true');
                const { data } = await axios.get(fcoUrl('/auth/rest/refresh'), { headers: { Authorization: `Bearer ${state.auth.refresh_token}` } });
                commit('setAuthTokens', authWithExpiration(data));
            } finally {
                localStorage.removeItem(refreshLockKey);
            }
        }

        dispatch('startRefreshTimer');
    },
    logout({ commit, dispatch }) {
        clearTimeout(refreshTimeout);
        commit('setAuthTokens', null);
        dispatch('requestAll', { requests: ['getFeatures', 'getCMSData'] });
        commit('setUser', getDefaultUser());
        commit('setStickySessionId', null);
        commit('vehicleSelector/resetVehicleData');
        dispatch('partSelection/clearLookupDetails');
        commit('setCurrentShop', null);
        commit('setCompany', getDefaultCompany());
        commit('setTSM', {});

        // TODO remove this line when we stop using LivePerson
        setLivePersonAuthenticated(false);
    },
    startRefreshTimer({ state, dispatch }) {
        clearTimeout(refreshTimeout);

        const now = Date.now();
        const { accessExpiresOn, refreshExpiresOn } = state.auth || {};

        // If we have no refresh token or it's expired, bail out here
        if (!refreshExpiresOn || refreshExpiresOn <= now) return;

        const timeUntilAuthExpiration = accessExpiresOn - Date.now();
        refreshTimeout = setTimeout(() => {
            dispatch('refreshAuth');
        }, timeUntilAuthExpiration);
    },
    async getUser({ state, commit, getters, dispatch }) {
        const { isSPA } = state;
        const response = await trackRequestStatus(commit, 'getUser', isSPA ? getCurrentUser() : getCurrentUserLegacy());
        const userWithShops = response?.data || {};

        // The getCurrentUserLegacy() endpoint currently returns as 200 for unauthenticated users
        // but returns text/html because it's actually redirecting the request to the login page.
        // If no userId, it means we don't have a valid user, so treat it like a 401 and clear any cached session.
        // This is only necessary for the MPA, where we rely heavily on the BE for session data.
        if (!isSPA && !userWithShops?.userId && getters.isAuthorizedUser) {
            dispatch('logout');
            return;
        }

        commit('setUser', userWithShops);

        // TODO remove this when we stop using LivePerson
        // Alert LivePerson about user authentication (we refreshed the page)
        setLivePersonAuthenticated(state.user.authenticated);
    },
    async getStickySessionId({ commit }) {
        const response = await trackRequestStatus(commit, 'getStickySessionId', axios.get(fcoUrl('/current/stickySessionInfo')));
        if (!response?.data) return;
        const { excludedFromStickySession, userId, shopId } = response.data;
        const stickySessionId = excludedFromStickySession ? `${userId}_${shopId}` : `${shopId}`;
        commit('setStickySessionId', stickySessionId);
    },
    async getUserShops({ commit }) {
        const response = await trackRequestStatus(commit, 'getUserShops', getCurrentUserShops());
        commit('setShops', response?.data || []);
    },
    async saveUserPreferences(
        { commit, state: { user } },
        {
            firstName = user.firstName,
            lastName = user.lastName,
            emailAddress = user.emailAddress,
            loginName = user.loginName,
            selectedStylesheet = user.userPreference?.selectedStylesheet,
            languageCode = user.userPreference?.languageCode,
        }
    ) {
        const userPreferencesData = {
            firstName,
            lastName,
            emailAddress,
            loginName,
            selectedStylesheet,
            languageCode,
        };
        await saveUserPreferences({ ...userPreferencesData, userId: user.userId });
        commit('updateUserPreferences', userPreferencesData);
    },
    async saveCompanyPreferences({ commit }, companyData) {
        await saveCompanyPreferences(companyData);
        commit('updateCompanyPreferences', companyData);
    },
    async getFeatures({ commit }) {
        const response = await trackRequestStatus(commit, 'getFeatures', getFeatures());
        commit('setFeatures', response?.data || []);
    },
    async getCurrentShop({ state, commit, dispatch, getters }) {
        const response = await trackRequestStatus(
            commit,
            'getCurrentShop',
            getters.selectedShopId
                ? getShopDetailsById(getters.selectedShopId, false, true)
                : // If we don't have a selected shop ID, it could mean we don't yet have the user (where the previous selection is stored).
                  // In that case, wait for the user request, then try again.
                  // If we _still_ don't have a selected shop ID (very unlikely), we'll default to the first shop in the user's shop list
                  dispatch('requestIfIdle', ['getUser'])
                      .then(() => {
                          if (getters.selectedShopId) return Promise.resolve(getters.selectedShopId);
                          return dispatch('requestIfIdle', ['getUserShops']).then(() => state.shops[0].id);
                      })
                      .then((shopId) => getShopDetailsById(shopId, false, true))
        );
        commit('setCurrentShop', response?.data);
    },
    async getShopRequests({ state, commit, dispatch }) {
        const response = await trackRequestStatus(
            commit,
            'getShopRequests',
            dispatch('requestIfIdle', ['getCurrentShop']).then(() => axios.get(fcoUrl(`/shop/${state.currentShop.id}/company/shopRequests`)))
        );
        commit('setShopRequests', response?.data || []);
    },
    async getCompany({ state, commit, dispatch }) {
        const response = await trackRequestStatus(
            commit,
            'getCompany',
            dispatch('requestIfIdle', ['getCurrentShop']).then(() => axios.get(fcoUrl(`/shop/${state.currentShop.id}/company`)))
        );
        commit('setCompany', response?.data || getDefaultCompany());
    },
    async getTSM({ commit }) {
        const { data: tsm } = await trackRequestStatus(commit, 'getTSM', axios.get(fcoUrl('/current/tsm')));
        commit('setTSM', tsm);
    },
    async getAppData({ commit }) {
        const { data } = await trackRequestStatus(commit, 'getAppData', axios.get(fcoUrl('/current/static')));
        commit('setAppData', data);
    },
    async getCMSData({ commit }) {
        const cmsData = await trackRequestStatus(commit, 'getCMSData', getCMSData());
        commit('setCMSData', cmsData || getDefaultCmsData());
    },
    getUSStates({ commit }) {
        const url = fcoUrl('/constants?states=true');

        trackRequestStatus(commit, 'getUSStates', axios.get(url)).then((response) => {
            commit('setUSStates', response.data.states);
        });
    },
    getJobTitles({ commit }) {
        const url = fcoUrl('/constants?jobTitles=true');

        trackRequestStatus(commit, 'getJobTitles', axios.get(url)).then((response) => {
            commit('setJobTitles', response.data.jobTitles);
        });
    },
    getCustomerTypes({ commit }) {
        const url = fcoUrl('/constants?customerTypes=true');

        trackRequestStatus(commit, 'getCustomerTypes', axios.get(url)).then((response) => {
            commit('setCustomerTypes', response.data.customerTypes);
        });
    },
    async saveUserEmailAddress({ commit }, email) {
        await saveUserEmail(email);

        commit('setUserEmailAddress', email);
        commit('setUserEmailUpdateRequired', false);
    },
    async declineUserEmailUpdate({ commit }) {
        await declineUserEmailUpdate();
        commit('setUserEmailUpdateRequired', false);
    },
    async refetchUserCriticalData({ dispatch }) {
        await dispatch('requestAll', { requests: ['getUserShops', 'getCurrentShop', 'getFeatures', 'getCompany', 'getTSM'] });
    },
    async masquerade({ dispatch, commit, state }, userId) {
        const { isSPA } = state;

        // While we transition from legacy to SPA architecture, we need to call the legacy masquerade endpoint to get a new session cookie
        const [authResponse] = await Promise.all([!isSPA ? Promise.resolve() : masquerade(userId), masqueradeLegacy(userId)]);

        const masqueradeRealUser = {
            auth: { ...state.auth },
            userId: state.user.userId,
        };

        if (isSPA) {
            commit('setAuthTokens', authWithExpiration(authResponse?.data));
        }

        await dispatch('getStickySessionId');

        commit('setMasqueradeRealUser', masqueradeRealUser);

        // Set the current page so we can go back once the user stops masquerading
        const currentPage = isSPA ? window.location.hash.slice(1) : window.location.href;
        commit('setMasqueradeSource', currentPage);

        // need to make sure FE vehicle data persistence clears out any selected vehicles anytime masquerade happens
        await dispatch('vehicleSelector/clearCurrentVehicle');

        dispatch('getUser');
        await requestSettled(() => state.requests.getUser);

        if (isSPA) {
            await dispatch('refetchUserCriticalData');
        }
    },
    async stopMasquerade({ state, commit, dispatch }) {
        const { isSPA } = state;

        // While we transition from legacy to SPA architecture, we need to call the legacy masquerade endpoint to get a new session cookie
        await Promise.all([!isSPA ? Promise.resolve() : stopMasquerade(), stopMasqueradeLegacy()]);

        if (isSPA) {
            commit('setAuthTokens', { ...state.masqueradeRealUser.auth });
        }

        await dispatch('getStickySessionId');

        // need to make sure FE vehicle data persistence clears out any selected vehicles anytime masquerade ends
        await dispatch('vehicleSelector/clearCurrentVehicle');

        dispatch('getUser');
        await requestSettled(() => state.requests.getUser);

        commit('setMasqueradeRealUser', null);

        if (isSPA) {
            await dispatch('refetchUserCriticalData');
        }
    },
    async selectShop({ commit, dispatch }, selectedShopId) {
        await selectShop(selectedShopId);
        commit('updateUserPreferences', { selectedShopId });
        await dispatch('requestAll', { requests: ['getCurrentShop', 'getFeatures', 'getCompany', 'getTSM', 'getStickySessionId'] });
    },
};

const cxmlCustomerTypes = new Set([
    'JDBYRIDER',
    'COKE',
    'SONIC',
    'WASTEMANAGEMENT',
    'SSA',
    'CARVANA',
    'TEAMCARCARE',
    'BIGBRANDTIRE',
    'VIPSHOPMANAGEMENT',
    'DRIVE',
]);

const getters = {
    isAuthExpired: ({ auth }) => !auth || auth?.accessExpiresOn < Date.now(),
    isRefreshExpired: ({ auth }) => !auth || auth?.refreshExpiresOn < Date.now(),
    isMasquerade: ({ isSPA, masqueradeRealUser, user }) => {
        if (!masqueradeRealUser || !user) return false;
        if (!isSPA) return user.masquerade;
        return masqueradeRealUser.userId !== user?.userId;
    },
    // Unless the user is masquerading, if password reset is required, user should not be allowed full access
    isAuthorizedUser: ({ isSPA, user, isPasswordChangeRequired, isTermsAcceptRequired }, { isRefreshExpired, isMasquerade }) => {
        if (!isSPA) return user.authenticated && (!user.passwordChangeRequired || user?.masquerade);
        return !isRefreshExpired && Boolean(user) && ((!isPasswordChangeRequired && !isTermsAcceptRequired) || isMasquerade);
    },
    isTeamMember: ({ isSPA, user }, { isAuthorizedUser }) => {
        if (!isAuthorizedUser) return false;
        if (!isSPA) return user.teamMember;
        return user.group.internal;
    },
    isWebUser: ({ user }) => !user.customerType?.name || user.customerType?.name === 'WEB',
    isMitchell1User: ({ user }) => user.customerType?.name === 'MITCHELL',
    isROWriterUser: ({ user }) => user.customerType?.name === 'ROWRITER',
    isSmartEquipUser: ({ user }) => user.customerType?.name === 'SMARTEQUIP',
    isCXMLUser: ({ user }) => cxmlCustomerTypes.has(user.customerType?.name),
    isSMSUser: ({ user, isSPA }) => Boolean(isSPA ? user?.customerType?.sms : user.customerTypeSms),
    isDemo: ({ company }) => Boolean(company?.demo),
    selectedShopId: ({ user }) => user?.userPreference?.selectedShopId,
    isLaborHidden: (_, { isROWriterUser }) => isROWriterUser,
    // All TMs default to store 4031. We need to hide this store's phone number from them so the store doesn't get overwhelmed with calls from TMs logging in with their accounts.
    // see: RWD-2327
    isStorePhoneHidden: ({ currentShop }, { isTeamMember }) => currentShop?.homeStore?.storeId === 4031 && isTeamMember,
    isPartsPayoffVisible: ({ company }, { userCanAccessSupport, userHasAnyRole }) => {
        if (userCanAccessSupport) return true;
        return company?.partsPayoffVisible && userHasAnyRole(Role.COMPANY_MANAGER, Role.SHOP_MANAGER);
    },
    isShopReferralEligible: ({ company }, { userCanAccessSupport, userHasAnyRole }) => {
        if (userCanAccessSupport) return true;
        return company?.shopReferralEligible && userHasAnyRole(Role.COMPANY_MANAGER, Role.SHOP_MANAGER);
    },
    userHasAnyRole:
        ({ user, isSPA }) =>
        (...roles) => {
            const roleName = isSPA ? user?.group?.name : user?.group;
            return Boolean(roleName) && roles.some((role) => roleName === role);
        },
    userCanAccessSupport: (state, { userHasAnyRole }) => userHasAnyRole(Role.SUPERUSER, Role.SYSADMIN, Role.INSTALLER_SUPPORT, Role.MARKETING),
    userCanEditCompany: (state, { isSMSUser, userHasAnyRole }) =>
        !isSMSUser && userHasAnyRole(Role.SUPERUSER, Role.INSTALLER_SUPPORT, Role.COMPANY_MANAGER, Role.DEMO_USER),
    userCanEditShop: (state, { isSMSUser, userCanEditCompany, userHasAnyRole }) =>
        !isSMSUser && (userCanEditCompany || userHasAnyRole(Role.SYSADMIN, Role.SHOP_MANAGER, Role.MARKETING)),
    userCanViewStatements: (state, { userHasAnyRole }) =>
        userHasAnyRole(
            Role.SUPERUSER,
            Role.SYSADMIN,
            Role.INSTALLER_SUPPORT,
            Role.COMPANY_MANAGER,
            Role.SHOP_MANAGER,
            Role.MARKETING,
            Role.OREILLY_EMPLOYEE,
            Role.DEMO_USER,
            Role.SMS
        ),
    userCanViewLaborClaims: (state, { userHasAnyRole }) =>
        userHasAnyRole(
            Role.SUPERUSER,
            Role.SYSADMIN,
            Role.INSTALLER_SUPPORT,
            Role.COMPANY_MANAGER,
            Role.SHOP_MANAGER,
            Role.MARKETING,
            Role.OREILLY_EMPLOYEE,
            Role.DEMO_USER,
            Role.SMS
        ),
    userCanViewActivityLog: (state, { isSMSUser, userCanEditCompany, userHasAnyRole }) =>
        !isSMSUser && (userCanEditCompany || userHasAnyRole(Role.SYSADMIN, Role.SHOP_MANAGER)),
    featuresByKey: ({ features }) =>
        features.reduce((featuresByKey, feature) => {
            featuresByKey[feature.descriptor] = feature;
            return featuresByKey;
        }, {}),
    isFeatureDisabled:
        ({ features }, { featuresByKey }) =>
        (descriptor) =>
            !features.length || featuresByKey[descriptor]?.enabled === false,
    userDefaultLandingPage: (
        { isSPA, currentShop },
        { isAuthorizedUser, userHasAnyRole, isMitchell1User, isROWriterUser, isSmartEquipUser, isCXMLUser }
    ) => {
        if (!isAuthorizedUser) {
            return !isSPA ? '/login.html' : '/login';
        }
        if (userHasAnyRole(Role.INSTALLER_SUPPORT, Role.MARKETING)) {
            return !isSPA ? '/admin/support.html' : '/support';
        }
        if (currentShop?.carCustomer) {
            return !isSPA ? '/carLanding.html' : '/car-admin';
        }
        if (isMitchell1User || isROWriterUser || isSmartEquipUser || isCXMLUser) {
            return !isSPA ? '/catalog/browse.html' : '/catalog/browse';
        }
        return '/';
    },
};

const store = new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules: {
        vehicleSelector,
        miniQuote,
        stockOrderQuickAdd,
        opp,
        kits,
        partSelection,
    },
});

// If the localStorage copy of our store state is updated in another tab or window, keep the current state here in sync.
const mutationsByStorageKey = new Map([
    [StorageKey.USER, 'setUser'],
    [StorageKey.AUTH, 'setAuthTokens'],
    [StorageKey.MASQUERADE_SOURCE, 'setMasqueradeSource'],
    [StorageKey.CURRENT_VEHICLE, 'vehicleSelector/setCurrentVehicle'],
]);
window.addEventListener('storage', ({ key }) => {
    if (!mutationsByStorageKey.has(key)) return;
    store.commit(mutationsByStorageKey.get(key), getStorageItem(key));
    if (key === StorageKey.AUTH) {
        store.dispatch('startRefreshTimer');
    }
});

export default store;
